<style scoped>
/* remove underline from text fields */
.no-underline>>>.v-input__slot::before,
.no-underline>>>.v-input__slot::after {
	border-bottom: none !important;
	border-color: white !important;
}

.no-underline>>>.v-input__slot {
	font-size: 14px !important;
}
</style>
<template>
	<v-card outlined rounded>
		<v-card-title>
			<span>{{ $t('SalesOrders.Sales_order') }} {{ order.ordernr }}</span>
			<v-spacer />
			<v-btn plain @click.stop="previewDocument">
				<v-icon>mdi-file-document-outline</v-icon>
				<span>pdf</span>
			</v-btn>
		</v-card-title>
		<v-card-text>
			<v-row justify="start">
				<v-col cols="auto">
					<v-text-field class="no-underline" :label="$t('Date')" :value="$d(new Date(order.datum), 'short')"
						readonly />
				</v-col>
				<v-col cols="auto">
					<v-text-field v-if="order.ref" class="no-underline" :label="$t('Your_reference')" :value="order.ref"
						readonly />
				</v-col>
				<v-col cols="auto">
					<v-text-field class="no-underline" readonly :label="$t('Net_total')"
						:value="$n(order.netto, 'decimal') + ' ' + order.muntcode" />
				</v-col>
				<v-spacer />
				<v-col cols="auto">
					<v-chip v-if="order.ordertype" label small color="info" outlined>{{
						order.ordertype
					}}</v-chip>
				</v-col>
				<v-col cols="auto" align="right">
					<v-chip label small color="info" outlined>{{
						$t('SalesOrders.Order_status.' + order.ostatus)
					}}</v-chip>
				</v-col>
			</v-row>
			<v-sheet outlined>
				<v-data-table disable-sort disable-pagination hide-default-footer :items="order.orderLine"
					:headers="orderLineHeaders" dense height="175" fixed-header>
					<template v-slot:[`item.ehp`]="{ item }">
						<span>{{ $n(item.ehp, "decimal") }}</span>
					</template>
					<template v-slot:[`item.orderLine`]="{ item }">
						<span>{{ item.aantal - item.aantalgeleverd }}</span>
					</template>
					<template v-slot:[`item.hkort`]="{ item }">
						<span v-if="item.hkort">{{
							$n(item.hkort * 0.01, "percent")
						}}</span>
					</template>
				</v-data-table>
			</v-sheet>
		</v-card-text>
	</v-card>
</template>

<script>
import caas from "@/services/caas";
export default {
	name: "OrderCard",
	props: {
		order: Object,
	},
	data() {
		return {
			orderLineHeaders: [
				{ text: this.$t('Product_code'), value: "anr" },
				{
					text: this.$t('Product_description'),
					value: "omschrijving",
					align: "start",
					width: "40%",
				},
				{ text: this.$t('Quantity'), value: "aantal", align: "end" },
				{
					text: this.$t("Backorder"),
					value: "orderLine",
					align: "end",
				},
				{ text: this.$t('Unit_price'), value: "ehp", align: "end" },
				{ text: "", value: "hkort", align: "end" },
				{ text: this.$t('Vat_percentage'), value: "btw", align: "end" },
			],
		};
	},
	computed: {},
	methods: {
		previewDocument() {
			let params = {
				documentId: this.order.ordernr,
				documentType: "OR",
			};
			caas.rpc("getDocumentUrl", params).then((response) => {
				window.open(response.data.success.documentUrl);
			});
		},
	},
};
</script>

