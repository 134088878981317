<template>
	<v-container fluid>
		<v-row class="primary sticky-top">
			<v-col cols="12">
				<v-toolbar flat color="primary" dark>
					<v-toolbar-title> {{ $t("SmartNavBar.My_orders") }} </v-toolbar-title>
					<v-toolbar-items class="kmp">
						<v-tabs background-color="transparent" v-model="tabId" @change="onTabChanged" centered
							show-arrows hide-slider class="my-auto">
							<v-tab>{{ $t("SalesOrders.All_orders") }}</v-tab>
							<v-tab>{{ $t("SalesOrders.Open_orders") }}</v-tab>
						</v-tabs>
					</v-toolbar-items>
					<v-spacer />
					<v-text-field flat hide-details clearable solo light dense class="my-auto"
						:placeholder="$t('Your_reference')" v-model="searchValue" append-icon="mdi-magnify"
						@keyup.native.enter="onSearch" @click:append="onSearch" @click:clear="onClear"
						style="max-width: 250px" />
				</v-toolbar>
			</v-col>
			<v-progress-linear v-if="loading" bottom indeterminate striped color="white"></v-progress-linear>
		</v-row>
		<v-row justify="center" v-for="order of orderList" :key="order.ordernr">
			<v-col cols="12" md="9" lg="7">
				<OrderCard :order="order" />
			</v-col>
		</v-row>
		<v-row justify="center">
			<v-btn class="my-3" v-if="this.rowIdent" :loading="loading" color="primary" @click="getOrderList()">
				{{ $t('Load_more') }}
			</v-btn>
		</v-row>
	</v-container>
</template>

<script>
import caas from "@/services/caas";
import OrderCard from "../components/OrderCard";
export default {
	name: "SalesOrders",
	components: { OrderCard },
	data() {
		return {
			loading: false,
			tabId: 0,
			orderList: [],
			rowsToBatch: 10,
			rowNum: 0,
			rowIdent: "",
			searchValue: "",
		};
	},
	methods: {
		getOrderList() {
			this.loading = true;
			let params = {
				statusFilter: this.tabId,
				rowNum: this.rowNum,
				rowIdent: this.rowIdent,
				rowsToBatch: this.rowsToBatch,
				search: this.searchValue,
			};
			return caas
				.rpc("getPortalUserOrders", params)
				.then((response) => {
					this.loading = false;
					if (response.data.success.data) {
						this.orderList.push(
							...response.data.success.data.orderView.order
						);
						this.rowNum = response.data.success.rowNum;
						this.rowIdent = response.data.success.rowIdent;
					} else if (response.data.success.error) {
						throw response.data.success.error;
					}
				})
				.catch((error) => {
					console.log(error.message);
					this.loading = false;
				})
		},
		onTabChanged() {
			this.orderList = [];
			this.rowNum = 0;
			this.rowIdent = "";
			this.getOrderList();
		},
		onClear() {
			this.searchValue = "";
			this.onSearch();
		},
		onSearch() {
			this.orderList = [];
			this.rowNum = 0;
			this.rowIdent = "";
			this.getOrderList();
		},
	},
	mounted() {
		this.getOrderList();
	},
};
</script>
